import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "../components/layout.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import ICF from "../images/mastering-your-ICF-credential.jpg"
import IA from "../images/image-asset.jpg"
import SEO from "../components/seo"

const Resources = ({ data }) => (
	<Layout>
		<SEO title="Resources" description={data.site.siteMetadata.description} />
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">Resources</h6>
					<h1>
						<strong>ICF CREDENTIAL / ENGAGEMENT PROGRAM</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content resources">
			<div class="container">
				<div className="row justify-content-between align-items-center pt-5">
					<div className="col">
						<div className="resources-content">
							<div className="row">
								<div className="col-md-6 mb-7">
									<div className="item mb-4">
										<div className="item-image">
											<LazyLoadImage
												className="img-fluid"
												src={ICF}
												alt="ICF"
											/>
										</div>
										<div className="item-info text-center">
											<h3 className="item-name">
												Mastering Your ICF Credential
											</h3>
											<h4 className="item-price">$ 25.00</h4>
											<div className="item-btn">
												<Link to="/resources/w23oadkqeru3ts1j7mt96e82ztt531">
													Register Now
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="item">
										<div className="item-image">
											<LazyLoadImage
												className="img-fluid"
												src={IA}
												alt="Coaching Engagement Program"
											/>
										</div>
										<div className="item-info text-center">
											<h3 className="item-name">Coaching Engagement Program</h3>
											<h4 className="item-price">$ 75.00 Every Month</h4>
											<div className="item-btn">
												<Link to="/resources/f250aeenb0azh81jco71e0nwnhjm2q">
													Register Now
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section id="information" className="pt-0">
			<div className="container">
				<div className="col">
					<h4>CONTACT INFORMATION:</h4>
					<p>
						<a href="mailto:impact@coachingforgood.org">
							impact@coachingforgood.org
						</a>
					</p>
					<p>
						<a href="tel:7198002149">719.800.2149</a>
					</p>
				</div>
			</div>
		</section>
	</Layout>
)

export default Resources

export const resourcesQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
